var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mb-12"},[_c('v-tabs',{attrs:{"color":"primary","background-color":"white","centered":"","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab,staticClass:"text-capitalize",attrs:{"href":`#${tab}`,"value":tab,"ripple":""}},[_vm._v(" "+_vm._s(_vm.tabHeaders[tab])+" ")])}),1),_c('v-card-text',[_c('v-window',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-window-item',{key:tab,attrs:{"value":tab}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"footer-props":_vm.footerProps,"items":tab === 'default'
                  ? _vm.genericMessagesDefaults
                  : _vm.genericMessagesGlobals,"loading":_vm.isLoading,"options":tab === 'default' ? _vm.optionsDefaults : _vm.optionsGlobals},on:{"update:options":function($event){tab === 'default' ? _vm.optionsDefaults : _vm.optionsGlobals=$event}},scopedSlots:_vm._u([{key:"item.attributes.keyName",fn:function({ item }){return [_c('router-link',{staticClass:"view-link",attrs:{"to":{
                    name: 'GenericMessagesEdit',
                    params: { id: item.id },
                  }}},[_vm._v(" "+_vm._s(item.attributes.keyName)+" ")])]}},{key:"item.view",fn:function({ item }){return [_c('v-btn',{staticClass:"mt-1",attrs:{"to":{
                    name: 'GenericMessagesEdit',
                    params: { id: item.id },
                  },"color":"primary"}},[_vm._v(" Edit ")])]}},{key:"no-data",fn:function(){return [_c('no-data-alert')]},proxy:true}],null,true)})],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }