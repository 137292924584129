<template>
  <v-card-text>
    <generic-messages-table>
    </generic-messages-table>
  </v-card-text>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GenericMessagesTable from "@admin/components/generic_messages/Table";

export default {
  name: "GenericMessagesIndex",
  components: {
    GenericMessagesTable,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["acceptRoles"]),
  },
  methods: {
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setTitleObject",
    ]),
  },
  async mounted() {
    this.setBreadcrumbs();
    this.setTitleObject("Generic Messages");
  },
  destroyed() {
    this.clearActionButtons();
    this.clearTitleObject();
  },
};
</script>

<style scoped></style>
