<template>
  <v-card class="mb-12">
    <v-tabs
      v-model="tab"
      color="primary"
      background-color="white"
      centered
      grow
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab"
        :href="`#${tab}`"
        :value="tab"
        ripple
        class="text-capitalize"
      >
        {{ tabHeaders[tab] }}
      </v-tab>
    </v-tabs>

    <v-card-text>
      <v-window v-model="tab">
        <v-window-item v-for="tab in tabs" :key="tab" :value="tab">
          <v-card flat>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :footer-props="footerProps"
                :items="
                  tab === 'default'
                    ? genericMessagesDefaults
                    : genericMessagesGlobals
                "
                :loading="isLoading"
                :options.sync="
                  tab === 'default' ? optionsDefaults : optionsGlobals
                "
              >
                <template v-slot:item.attributes.keyName="{ item }">
                  <router-link
                    class="view-link"
                    :to="{
                      name: 'GenericMessagesEdit',
                      params: { id: item.id },
                    }"
                  >
                    {{ item.attributes.keyName }}
                  </router-link>
                </template>
                <template v-slot:item.view="{ item }">
                  <v-btn
                    class="mt-1"
                    :to="{
                      name: 'GenericMessagesEdit',
                      params: { id: item.id },
                    }"
                    color="primary"
                  >
                    Edit
                  </v-btn>
                </template>
                <template v-slot:no-data>
                  <no-data-alert />
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import NoDataAlert from "@admin/components/NoDataAlert.vue";
import _ from "lodash";
import { humanize } from "ora-utils/src/filters/stringHelpers";

export default {
  name: "GenericMessagesTable",
  filters: { humanize },
  components: {
    NoDataAlert,
  },
  computed: {
    ...mapState(["footerProps"]),
    ...mapGetters("genericMessages", {
      genericMessagesDefaults: "genericMessagesDefaultsList",
      genericMessagesDefaultsCount: "genericMessagesDefaultsListCount",
      genericMessagesGlobals: "genericMessagesGlobalsList",
      genericMessagesGlobalsCount: "genericMessagesGlobalsListCount",
    }),
    pageAndSortData() {
      const data = (({ page, itemsPerPage, messageType }) => ({
        page,
        limit: itemsPerPage,
        messageType,
      }))(this.tab === "default" ? this.optionsDefaults : this.optionsGlobals);

      return data;
    },
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: "Key Name",
          value: "attributes.keyName",
        },
        {
          text: "English Translation",
          value: "attributes.message",
          sortable: false,
        },
        {
          text: "Action",
          value: "view",
          sortable: false,
        },
      ],
      optionsDefaults: {
        page: 1,
        itemsPerPage: 50,
        sortDesc: [],
        messageType: "default",
      },
      optionsGlobals: {
        page: 1,
        itemsPerPage: 50,
        sortDesc: [],
        messageType: "global",
      },
      tabs: ["global", "default"],
      tab: null,
      tabHeaders: {
        global: "Global Fields & Descriptions",
        default: "Default Messages",
      },
    };
  },
  methods: {
    ...mapActions("genericMessages", [
      "getGenericMessages",
      "clearGenericMessages",
    ]),
    async handleGenericMessagesUpdate(options) {
      this.isLoading = true;
      await this.getGenericMessages(options);
      this.isLoading = false;
    },
  },
  watch: {
    pageAndSortData: {
      async handler(currentValue, oldValue) {
        if (_.isEqual(currentValue, oldValue)) return;
        // Message type determines the affected genericMessages list
        // If the number of genericMessages we have in the store
        // is less than the number of genericMessages that exist in the database table
        // AND the current limit is greater than the number of genericMessages that we have in the store
        // then we need to fetch more genericMessages.
        const affectedMessages =
          this.tab === "default"
            ? this.genericMessagesDefaults
            : this.genericMessagesGlobals;
        const affectedMessagesCount =
          this.tab === "default"
            ? this.genericMessagesDefaultsCount
            : this.genericMessagesGlobalsCount;
        if (
          affectedMessages.length < affectedMessagesCount &&
          currentValue.limit >= affectedMessages.length
        ) {
          await this.handleGenericMessagesUpdate(currentValue);
        }
      },
      deep: true,
    },
  },
  async mounted() {
    if (
      this.genericMessagesDefaults === undefined ||
      this.genericMessagesDefaults.length === 0
    ) {
      this.isLoading = true;
      await this.getGenericMessages({ messageType: "default" });
      this.isLoading = false;
    }
    if (
      this.genericMessagesGlobals === undefined ||
      this.genericMessagesGlobals.length === 0
    ) {
      this.isLoading = true;
      await this.getGenericMessages({ messageType: "global" });
      this.isLoading = false;
    }
  },
  destroyed() {
    this.clearGenericMessages("default");
    this.clearGenericMessages("global");
  },
};
</script>
